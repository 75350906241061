<template>
  <div v-show="exibir" class="App" />
</template>

<script>
import MarkerClusterer from "@google/markerclusterer";
import gmapsInit from "./GoogleMapsConfig.js";

export default {
  name: `App`,
  async mounted() {
    this.iniciar();
  },
  props: {
    locations: {
      type: Array,
      default: null,
    },
    exibir: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  watch: {
    locations: {
      handler() {
        this.$nextTick(() => {
          if (this.locations) {
            this.iniciar();
          }
        });
      },
      // Ativa o watch desde o create
      immediate: true,
    },
  },
  methods: {
    async iniciar() {
      try {
        const google = await gmapsInit();
        const map = new google.maps.Map(this.$el);
        map.setZoom(16);
        map.setCenter(
          new google.maps.LatLng(
            this.locations[0].position.lat,
            this.locations[0].position.lng
          )
        );
        const markers = this.locations.map((location) => {
          const marker = new google.maps.Marker({ ...location, map });
          marker.addListener(`click`, () => markerClickHandler(marker));
          return marker;
        });
        const markerClickHandler = (marker) => {
          map.setZoom(13);
          map.setCenter(marker.getPosition());
        };
        // eslint-disable-next-line no-new
        new MarkerClusterer(map, markers, {
          imagePath: `https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m`,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
.App {
  width: 100vw;
  height: 100vh;
}
</style>
