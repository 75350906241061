<template>
  <div>
    <!-- {{ placa }}{{ frota }}{{ condutor }}{{ data }}{{ latitude }}{{ longitude }}{{ velocidade }}{{ direcao }} -->
    {{ mensagem }}
    <google-maps v-bind:locations="locations" v-bind:exibir="exibir" />
  </div>
</template>

<script>
import util from "../../util/Util.js";
import eventBus from "@/util/EventBus.js";

import googleMaps from "@/components/googlemaps/GoogleMaps.vue";
export default {
  components: {
    "google-maps": googleMaps,
  },
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    mensagem: "",
    exibir: false,
    locations: [
      {
        position: {
          lat: -25.39048,
          lng: -49.22545,
        },
      },
    ],
    locations1: [
      {
        position: {
          lat: -25.39048,
          lng: -49.22545,
        },
      },
      {
        position: {
          lat: -25.39197,
          lng: -49.22769,
        },
      },
      {
        position: {
          lat: -25.391,
          lng: -49.22939,
        },
      },
    ],
  }),
  mounted() {
    // Metodo responsável por receber um eventbus do componente /usuario/login/LoginUsuario.vue, que autoriza a exibição e montagem do menu.
    // .bind(this) é necessário para que o eventBus possa acessar qualquer variável ou método do componente.
    eventBus.$on(
      util.cnt.EVENTBUS.veiculo.localizar,
      function(idVeiculo, placa) {
        this.listar(idVeiculo, placa);
        this.obterPosicao(placa);
      }.bind(this)
    );
  },
  methods: {
    // <v-btn v-on:click="executar()">ALOOOWW</v-btn>
    executar() {
      this.locations = this.locations1;
    },
    listar(idVeiculo, placa) {
      console.log("idVeiculo: " + idVeiculo + " placa: " + placa);
    },
    obterPosicao(placa) {
      this.exibir = false;
      // this.desabilitar(true);
      // this.limparLista();
      this.mensagem = "";
      this.showLoader();
      util.srv.veiculo
        .obterPosicao(placa)
        .then((response) => {
          this.distribuir(response.data.result);
        })
        .catch(() => {
          this.alertWarningToast("Coordenada atual inexistente.");
          //console.log(error);
        })
        .finally(() => {
          // this.desabilitar(false);
          // this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },
    distribuir(data) {
      var obj = JSON.parse(data);
      let loc = [
        {
          position: {
            lat: obj.latitude,
            lng: obj.longitude,
          },
        },
      ];
      this.mensagem =
        "Placa: " +
        obj.veiculoENT.placa +
        ",  Frota: " +
        obj.veiculoENT.frota +
        ",  Condutor: " +
        obj.condutorENT.condutor +
        " " +
        obj.condutorENT.nomeAbreviado +
        ",  Data " +
        this.formatarDataHora(obj.strDataGps) +
        ",  Velocidade " +
        obj.velocidadeVeiculo + " km/h"
        ;

      // placa: "",
      // frota: "",
      // condutor: "",
      // data: "",
      // latitude: "",
      // longitude: "",
      // velocidade: "",
      // direcao: "",

      this.locations = loc;
      this.exibir = true;
    },
  },
};
</script>
